import { logError } from 'js/utils/app';
import { TUserAnnouncementsConfig } from 'submodule/Announcements/types';
import { userAnnouncementsConfigValidator } from 'submodule/Announcements/utils';

export const userAnnouncementsConfigNormalizer = {
	normalize(config: TUserAnnouncementsConfig | null | undefined): TUserAnnouncementsConfig | null {
		if (!config) {
			return null;
		}

		try {
			userAnnouncementsConfigValidator.validateSync(config, { strict: true });
		} catch (e) {
			logError('userAnnouncementsConfigNormalizer', e);
			return null;
		}

		return config;
	},
};
