import React, { ReactElement, useEffect, useMemo } from 'react';
import type { IUiTableProps } from '@avast/react-ui-components';
import { UiTable } from '@avast/react-ui-components';
import { IApiSortBy } from 'types/api';
import { useCompare } from 'js/hooks/useCompare';
import { defaults, isEmpty, isString } from 'lodash';
import { orderObject, sortByToTableSortingState } from 'js/utils/common';
import { TUseApiListModule } from 'js/queries/useApiListQuery';
import { CONFIG } from 'config';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useTranslation } from 'react-i18next';
import { IListMetaDataValues } from 'types/utils';
import type { TableMeta } from '@tanstack/table-core';
import { useTableStateHelper } from 'js/components/molecules/DataTable/useTableStateHelper';

export type TApiListTableProps<Data extends object, Filter extends object> = Pick<IUiTableProps<Data>, 'columns'> & {
	query: TUseApiListModule<Data, Filter>;
	limit?: number;
	filter?: Filter | null;
	filterStatic?: Filter;
	filterRequired?: boolean | string;
	filterIsEmpty?: (values: Filter) => boolean;
	globalFilterUrlKey?: keyof Filter;
	sort?: IApiSortBy<Data>;
	table?: Partial<IUiTableProps<Data>>;
	className?: string;
	onMetaDataChange?: (values: IListMetaDataValues<Filter>) => void;
	useLocation?: boolean;
};

/**
 * Data table component with manual pagination, sorting, using api to fetch data
 * @param {TApiListTableProps} props
 * @returns {React.ReactElement}
 * @private
 */
export const ApiListTable = <D extends object, F extends object = {}>(
	props: TApiListTableProps<D, F>,
): ReactElement => {
	const {
		query: useQuery,
		limit = CONFIG.TABLE.pageSizeDefault,
		columns,
		sort,
		filterIsEmpty = isEmpty,
		filterStatic,
		onMetaDataChange,
		filterRequired,
		globalFilterUrlKey,
	} = props;
	const isFilterLoading = props.filter === null;
	const filter: F = props.filter || ({} as F);

	// Setup states
	const filterUpdated = useCompare<F>(filter, true);
	const emptyRequiredFilter = Boolean(filterRequired) && filterIsEmpty(filter);
	const [t] = useTranslation();
	const computedFilter = defaults({}, filterStatic, filter);

	// Uri filter
	const _filter = JSON.stringify(orderObject(filter));
	const globalFilterValue = props.table?.state?.globalFilter;
	const uriFilter = useMemo(() => {
		const filter = JSON.parse(_filter) as F;
		if (globalFilterUrlKey) {
			filter[globalFilterUrlKey] = globalFilterValue;
		}
		return filter;
	}, [_filter, globalFilterValue, globalFilterUrlKey]);

	// Table state helper
	const {
		setPagination,
		pagination,
		sorting,
		setSorting,
		locationLoaded: stateLocationLoaded,
	} = useTableStateHelper<D, F>({
		disabled: isFilterLoading,
		initialState: {
			sorting: sortByToTableSortingState<D>(sort),
			pagination: { pageSize: limit },
		},
		loadFromUrl: props.useLocation,
		tableFilter: uriFilter,
	});
	const locationLoaded = !isFilterLoading && stateLocationLoaded;

	/**
	 * Api hook call
	 */
	const queryDisabled = emptyRequiredFilter || isFilterLoading || !locationLoaded;
	const {
		query: { isError, isFetching },
		data,
	} = useQuery({
		filter: computedFilter,
		queryConfig: { enabled: !queryDisabled },
	});

	// On meta data changes
	const total = data.length;
	useEffect(() => {
		onMetaDataChange?.({
			totalCount: emptyRequiredFilter ? 0 : total,
			filter: JSON.parse(_filter),
		});
	}, [_filter, onMetaDataChange, total, emptyRequiredFilter]);

	/**
	 * Reset table when filter is updated
	 */
	useEffect(() => {
		if (filterUpdated && locationLoaded) {
			setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
		}
	}, [setPagination, filterUpdated, locationLoaded]);

	if (isFilterLoading || !locationLoaded) {
		return <LoadingPlaceholder type="BAR" />;
	}

	// Custom error
	let customError: TableMeta<D>['customError'];
	if (emptyRequiredFilter) {
		customError = isString(filterRequired) ? filterRequired : t('common:table.useFilter');
	}

	return (
		<UiTable<D>
			data={data}
			className={props.className}
			columns={columns}
			enablePagination
			enableSorting
			{...props.table}
			state={{ pagination, sorting, ...props.table?.state }}
			initialState={{ pagination, ...props.table?.initialState }}
			onPaginationChange={setPagination}
			onSortingChange={setSorting}
			meta={{
				isScrollable: true,
				nowrapCell: true,
				loading: emptyRequiredFilter ? isFilterLoading : isFetching,
				customError: customError || isError,
				scrollTopOnPageChange: true,
				...props.table?.meta,
			}}
		/>
	);
};
