import { TAnnouncementId, TUserAnnouncementsConfig } from 'submodule/Announcements/types';
import { Reducer } from 'react';
import { logError } from 'js/utils/app';

export type TUserAnnouncementsConfigState = {
	isReady: boolean;
	isChanged?: boolean;
	loading?: boolean;
	announcementsConfig: TUserAnnouncementsConfig;
	hiddenAnnouncementsIds: TAnnouncementId[];
};

type TUserAnnouncementsConfigReducerAction =
	| { type: 'SET_DATA'; payload: TUserAnnouncementsConfig }
	| { type: 'HIDE_ANNOUNCEMENT'; payload: TAnnouncementId };

export const userAnnouncementsConfigReducer: Reducer<
	TUserAnnouncementsConfigState,
	TUserAnnouncementsConfigReducerAction
> = (state, action) => {
	switch (action.type) {
		case 'SET_DATA':
			return {
				isReady: true,
				isChanged: false,
				loading: false,
				announcementsConfig: action.payload,
				hiddenAnnouncementsIds: action.payload.filter((item) => item.hidden).map((item) => item.id),
			};
		case 'HIDE_ANNOUNCEMENT':
			return {
				...state,
				isChanged: true,
				announcementsConfig: [
					...state.announcementsConfig.filter((config) => config.id !== action.payload),
					{ id: action.payload, hidden: true },
				],
				hiddenAnnouncementsIds: [...state.hiddenAnnouncementsIds, action.payload],
			};
		default:
			logError('Not supported action', action);
			return state;
	}
};
