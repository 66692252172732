import { TAnnouncementPlacement } from 'submodule/Announcements/types';
import React from 'react';
import { Alert } from '@avast/react-ui-components';
import { useUserAnnouncementsConfig, useValidAnnouncements } from 'submodule/Announcements/hooks';
import Skeleton from 'react-loading-skeleton';

export type TAnnouncementsProps = {
	className?: string;
	placement: TAnnouncementPlacement;
};

export const Announcements = (props: TAnnouncementsProps) => {
	const { hiddenAnnouncementsIds, loading: isUserConfigLoading, hideAnnouncement } = useUserAnnouncementsConfig();
	const { announcements, isLoading: isAnnouncementsLoading } = useValidAnnouncements(props.placement);

	if (isUserConfigLoading || isAnnouncementsLoading) {
		return (
			<div className="my-3">
				<Skeleton />
				<Skeleton width="30%" />
			</div>
		);
	}

	return (
		<>
			{announcements
				.filter((announcement) => !hiddenAnnouncementsIds.includes(announcement.id))
				.map((announcement) => (
					<Alert
						key={announcement.id}
						variant={announcement.variant}
						caption={announcement.caption?.()}
						closeable={announcement.hidable}
						onClose={() => hideAnnouncement(announcement.id)}
						icon={announcement.icon}
						{...props}
					>
						{announcement.render()}
					</Alert>
				))}
		</>
	);
};
