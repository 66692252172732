import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { ELLIPSIS_CELL_WIDTH } from 'appConstants';

type TTableData = IEntityDistributionPartner;

export const useDistributionPartnerListColumns = (
	onRowClick: (row: TTableData) => string,
): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.hidden('id'),
			columnHelper.link(
				'name',
				{
					header: t('common:entity.company'),
					meta: { formatters: [formatter.bold], truncateText: { width: ELLIPSIS_CELL_WIDTH } },
				},
				{
					to: onRowClick,
				},
			),
			columnHelper.text('contact.firstName', { header: t('common:contact.firstName') }),
			columnHelper.text('contact.lastName', { header: t('common:contact.lastName') }),
			columnHelper.copy2clipboard('contact.email', { header: t('common:contact.email') }),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: onRowClick,
					testId: 'detail',
				},
			}),
		];
	}, [t, onRowClick]);
};
