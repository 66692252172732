import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { IApiAnnouncementListFilter, IEntityAnnouncement } from 'submodule/Announcements/types';

const API_ANNOUNCEMENT_KEYS = {
	LIST: 'announcement',
};

export const useApiAnnouncementList: TUseApiListModule<IEntityAnnouncement, IApiAnnouncementListFilter> = (props) => {
	return useApiListQuery(API_ANNOUNCEMENT_KEYS.LIST, props);
};
