import { TAnnouncement } from 'submodule/Announcements/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthContext } from 'js/contexts';

export const useTaxExemptionCertificateAnnouncement = (): TAnnouncement => {
	const [t] = useTranslation('submodules');
	const { authCompanyGroups } = useAuthContext();

	return {
		id: 'eebc03a7-8c87-479e-b972-ae9bc8b93fda',
		hidable: false,
		show: authCompanyGroups.creditEligible,
		variant: 'warning',
		caption() {
			return <Trans t={t}>{'announcements.taxExemptionCertificate.title'}</Trans>;
		},
		render() {
			const email = 'Americas_Sales_Tax@nortonlifelock.com';

			return (
				<>
					<p>
						<Trans
							t={t}
							i18nKey="announcements.taxExemptionCertificate.contentTop"
							components={[
								<a
									href={`mailto:${email}`}
									target="_blank"
									rel="noopener noreferrer"
									key={0}
								>
									{email}
								</a>,
							]}
							values={{ email }}
						/>
					</p>
					<p>
						<Trans t={t}>{'announcements.taxExemptionCertificate.contentBottom'}</Trans>
					</p>
				</>
			);
		},
		placement: ['TAX_EXEMPTION_CERTIFICATE', 'TAX_EXEMPTION_REGISTRATION'],
	};
};
