import { TAnnouncement } from 'submodule/Announcements/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const useOmsDowntimeAnnouncement = (): TAnnouncement => {
	const [t] = useTranslation('submodules');

	return {
		id: '5dc1125b-25d1-4d71-bcc2-eabf90772a95',
		show: true,
		hidable: false,
		variant: 'warning',
		caption() {
			return <Trans t={t}>{'announcements.omsDowntime.title'}</Trans>;
		},
		render() {
			return (
				<>
					<p>
						<Trans t={t}>{'announcements.omsDowntime.contentTop'}</Trans>
					</p>
					<p>
						<Trans t={t}>{'announcements.omsDowntime.contentMiddle'}</Trans>
					</p>
					<p>
						<Trans t={t}>{'announcements.omsDowntime.contentBottom'}</Trans>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_INTERNAL', 'HOMEPAGE_PARTNER'],
	};
};
