import { useEffect, useReducer } from 'react';
import { AffiliateConfigEnum } from 'module/partners/enums';
import { TAnnouncementId, TUserAnnouncementsConfig } from 'submodule/Announcements/types';
import {
	affiliateConfigUiValueToApi,
	getAffiliateConfig_UserAnnouncements,
} from 'module/partners/utils/affiliateConfig';
import { logError } from 'js/utils/app';
import { useApiAffiliateConfigUpdate } from 'module/partners/hooks/useApiPartners';
import { TUserAnnouncementsConfigState, userAnnouncementsConfigReducer } from 'submodule/Announcements/reducers';
import { useAuthContext } from 'js/contexts';

type TUseUserAnnouncementsConfig = TUserAnnouncementsConfigState & {
	hideAnnouncement(id: TAnnouncementId): void;
};

export const useUserAnnouncementsConfig = (): TUseUserAnnouncementsConfig => {
	const {
		authPartnerId,
		authState: { token },
	} = useAuthContext();
	const [state, dispatch] = useReducer(userAnnouncementsConfigReducer, {
		isReady: false,
		loading: true,
		announcementsConfig: [],
		hiddenAnnouncementsIds: [],
	});

	// Prepare update config call
	const { mutateAsync: affiliateConfigUpdate } = useApiAffiliateConfigUpdate({
		config: { params: { id: authPartnerId, name: AffiliateConfigEnum.USER_ANNOUNCEMENTS } },
	});

	// Load on init
	useEffect(() => {
		getAffiliateConfig_UserAnnouncements({
			affiliateId: authPartnerId!,
			apiConfig: { authToken: token },
		})
			.then((payload) => dispatch({ type: 'SET_DATA', payload }))
			.catch(logError); // Pass error and just log to Sentry
	}, [authPartnerId, token]);

	// On hide new announcement
	useEffect(() => {
		if (!state.isReady || !state.isChanged) {
			return;
		}

		// Update in OMS
		const value = affiliateConfigUiValueToApi<TUserAnnouncementsConfig>(
			AffiliateConfigEnum.USER_ANNOUNCEMENTS,
			state.announcementsConfig,
		);
		affiliateConfigUpdate({ value }).catch(logError); // Pass error and just log to Sentry
	}, [state.announcementsConfig, state.isReady, state.isChanged, affiliateConfigUpdate]);

	return {
		...state,
		hideAnnouncement(id) {
			dispatch({ type: 'HIDE_ANNOUNCEMENT', payload: id });
		},
	};
};
