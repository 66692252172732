import type { ArraySchema } from 'yup';
import * as Yup from 'yup';
import { TUserAnnouncementConfig } from 'submodule/Announcements/types';

const itemValidator = Yup.object().shape<TUserAnnouncementConfig>({
	id: Yup.string().required(),
	hidden: Yup.boolean(),
});

// @ts-ignore
export const userAnnouncementsConfigValidator: ArraySchema<TUserAnnouncementConfig> =
	Yup.array<TUserAnnouncementConfig>().required().of(itemValidator);
