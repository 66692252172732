import { TAnnouncement, TAnnouncementPlacement } from 'submodule/Announcements/types';
import { useAnnouncements } from 'submodule/Announcements/hooks/useAnnouncements';
import { useApiAnnouncementList } from 'submodule/Announcements/api';
import { useAuthContext } from 'js/contexts';

type TValidAnnouncements = {
	isLoading?: boolean;
	announcements: TAnnouncement[];
};

export const useValidAnnouncements = (placement: TAnnouncementPlacement): TValidAnnouncements => {
	const list = useAnnouncements(placement);
	const {
		authCompanyGroups: { isTestAccount },
		isGroupPartner,
	} = useAuthContext();
	const {
		data: announcements,
		query: { isLoading: isAnnouncementsLoading },
	} = useApiAnnouncementList({ filter: { onlyValid: true } });

	return {
		isLoading: isAnnouncementsLoading,
		announcements: list.filter((item) => {
			return announcements.some((announcement) => {
				if (announcement.uuid !== item.id) {
					return false;
				}

				if (isGroupPartner && announcement.isTestPartner && !isTestAccount) {
					return false;
				}

				return true;
			});
		}),
	};
};
